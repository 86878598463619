html, body, #root {
  width: 100%;
  height: 100%;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
}

:root {
  --bg-1: #1d0165;
  --bg-2: #260677;
  --bg-3: #31029c;

  --green-1: #3be930;
  --green-2: #00a200;
  --green-3: #026617;

  --yellow-1: #fbe001;
  --yellow-2: #e8a306;
  --yellow-3: #da5908;

  --violet-1: #bb14ed;
  --violet-2: #8706e2;
  --violet-3: #6100a1;

  --blue-1: #0ff8f6;
  --blue-2: #098fe6;
  --blue-3: #064fc2;

  --red-1: #ffa091;
  --red-2: #c60a8b;
  --red-3: #99047b;

  --gold-1: #ffb300;
  --gold-2: #ffd700;
}


@font-face {
  font-family: 'Days Sans';
  src: local('Days Sans Black'), local('DaysSansBlack'), url('fonts/dayssansblack.woff2') format('woff2'), url('fonts/dayssansblack.woff') format('woff'), url('fonts/dayssansblack.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}


@font-face {
  font-family: 'Tahoma';
  src: url('fonts/tahoma.ttf');
  font-weight: 500;
  font-style: normal;
}


body {
  margin: 0;
  font-family: 'Tahoma';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
