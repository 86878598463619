.App {
  display: flex;
  flex-direction: column;
  
  width: 100%;
  height: 100%;

  background: linear-gradient(
    135deg,
    var(--bg-1),
    var(--bg-2),
    var(--bg-1),
    var(--bg-2),
    var(--bg-1),
    var(--bg-2)
  );
  animation: gradient 5s infinite alternate;
  background-size: 400%;

  color: white;
}

@keyframes gradient {
  0% {
    background-position: 80% 0%;
  }
  100% {
    background-position: 20% 100%;
  }
  /* 100% {
    background-position: 80% 0%;
  } */
}


/* Loading page */
.appLoading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--bg-1);
  z-index: 999;
}

.appLoading .loader {
  width: 48px;
  height: 48px;
  border: 5px solid #FFF;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  position: relative;
  animation: pulse 1s linear infinite;

  top: calc(50% - 24px);
  left: calc(50% - 24px);
  /* transform: translateX(-50%) translateY(-50%); */
}
.loader:after {
  content: '';
  position: absolute;
  width: 48px;
  height: 48px;
  border: 5px solid #FFF;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  animation: scaleUp 1s linear infinite;
}

@keyframes scaleUp {
  0% { transform: translate(-50%, -50%) scale(0) }
  60% , 100% { transform: translate(-50%, -50%)  scale(1)}
}
@keyframes pulse {
  0% , 60% , 100%{ transform:  scale(1) }
  80% { transform:  scale(1.2)}
}
