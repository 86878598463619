.container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.decor {
    position: absolute;
    width: 1.5rem;
    animation: floating 5s alternate infinite;
    animation-delay: var(--dl);
}

@keyframes floating {
    0% {
        transform: translateY(0);
    }

    25% {
        transform: translateY(5px);
    }

    50% {
        transform: translateY(0);
    }

    75% {
        transform: translateY(-5px);
    }

    100% {
        transform: translateY(0);
    }
}