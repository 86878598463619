.window {
  position: absolute;
  top: 0;
  left: 0;

  z-index: 3;

  width: 100%;
  /* min-height: 100%; */
  height: 100%;
  overflow-y: auto;

  background: linear-gradient(
    135deg,
    var(--bg-1),
    var(--bg-2),
    var(--bg-1),
    var(--bg-2),
    var(--bg-1),
    var(--bg-2)
  );
  animation: gradient 5s infinite alternate;
  background-size: 400%;

  color: white;
}

@keyframes gradient {
  0% {
    background-position: 80% 0%;
  }
  100% {
    background-position: 20% 100%;
  }
}
