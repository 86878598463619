.container {
  padding-top: 1rem;
}

.tourBtn {
  border: none;
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
  font-weight: 600;
  width: 100%;
  cursor: pointer;
  border-radius: 0.5rem;

  position: relative;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.01);

  margin-bottom: 2rem;
}

.tourBtn::after {
  position: absolute;
  content: "";
  top: 15px;
  left: 0;
  right: 0;
  z-index: -1;
  height: 100%;
  width: 100%;
  transform: scale(0.9) translateZ(0);
  filter: blur(15px);
  background: linear-gradient(
    to left,
    #ff5770,
    #e4428d,
    #c42da8,
    #9e16c3,
    #6501de,
    #9e16c3,
    #c42da8,
    #e4428d,
    #ff5770
  );
  background-size: 200% 200%;
  animation: animateGlow 1.25s linear infinite;
}

@keyframes animateGlow {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 200% 50%;
  }
}

.tour1 {
  background-color: #f0cf16;
  color: #9e16c3;
}

.tour1::after {
  background: linear-gradient(
    to left,
    var(--yellow-1),
    var(--yellow-2),
    var(--yellow-3),
    var(--yellow-2),
    var(--yellow-1)
  );
  background-size: 200% 200%;
}

.tour2 {
  background-color: var(--green-2);
  color: orange;
}

.tour2::after {
  background: linear-gradient(
    to left,
    var(--green-1),
    var(--green-2),
    var(--green-3),
    var(--green-2),
    var(--green-1)
  );
  background-size: 200% 200%;
}

.tour3 {
  background-color: var(--red-2);
  color: #1bd11b;
}

.tour3::after {
  background: linear-gradient(
    to left,
    var(--red-1),
    var(--red-2),
    var(--red-3),
    var(--red-2),
    var(--red-1)
  );
  background-size: 200% 200%;
}

.tour4 {
  background-color: var(--violet-1);
  color: #ff974c;
}

.tour4::after {
  background: linear-gradient(
    to left,
    var(--violet-1),
    var(--violet-2),
    var(--violet-3),
    var(--violet-2),
    var(--violet-1)
  );
  background-size: 200% 200%;
}

.counter {
  font-family: "Days Sans";
  color: white;
  width: 100%;
  text-align: center;
  margin-bottom: 2rem;
  font-size: 1.5rem;
  text-shadow: 0 0 10px var(--blue-1), 0 0 20px var(--blue-1);
}

.onCounter {
  font-family: "Days Sans";
  color: white;
  width: 100%;
  text-align: left;
  text-shadow: 0 0 10px var(--blue-1), 0 0 20px var(--blue-1);

}
