.ticketsCountText {
  font-size: 1.1rem;
  text-align: center;
}

.tickets {
  text-align: center;
  margin-top: 1rem;
}

.scrollModal {
  max-height: 70vh;
  overflow-y: auto;
}

