.middlePart {
  position: relative;
  width: 100%;
  flex: 1;
}

.superButtonCircles1, .superButtonCircles2, .superButtonCircles3 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 9rem;
  height: 9rem;
  transform-origin: 0 0;
}

.superButtonCircles1, .superButtonCircles3 {
  animation: rotateClockwise 10s linear infinite;
}

.superButtonCircles2 {
  animation: rotateCounterclockwise 10s linear infinite;
}

@keyframes rotateClockwise {
  from {
    transform: rotate(0deg) translate(-50%, -50%);
  }
  to {
    transform: rotate(360deg) translate(-50%, -50%);
  }
}

@keyframes rotateCounterclockwise {
  from {
    transform: rotate(0deg) translate(-50%, -50%);
  }
  to {
    transform: rotate(-360deg) translate(-50%, -50%);
  }
}


.superButton {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  text-align: center;
  line-height: 60px;
  cursor: pointer;
  color: white;
  font-size: 1.5rem;
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;
  border: none;
  font-family: sans-serif;
  box-sizing: border-box;
  background: linear-gradient(
    90deg,
    var(--green-1),
    var(--yellow-1),
    var(--violet-1),
    var(--blue-1),
    var(--red-2),
    var(--green-1)
  );
  background-size: 400%;
  border-radius: 50px;
  z-index: 1;

  animation: animate 16s linear infinite;
}

/* .superButton:hover {
  animation: animate 8s linear infinite;
} */

@keyframes animate {
  0% {
    background-position: 0%;
  }
  100% {
    background-position: 400%;
  }
}

.superButton:before {
  content: "";
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  z-index: -1;
  /* background: linear-gradient(45deg, var(--yellow-1), var(--red-2), var(--violet-1), var(--green-1)); */
  background: linear-gradient(
    45deg,
    var(--green-1),
    var(--yellow-1),
    var(--violet-1),
    var(--blue-1),
    var(--red-2),
    var(--green-1)
  );
  background-size: 400%;
  border-radius: 40px;
  opacity: 0;
  transition: 0.5%;

  filter: blur(20px);
  opacity: 1;
  animation: animate 16s linear infinite;
}

/* .superButton:hover:before {
  filter: blur(20px);
  opacity: 1;
  animation: animate 8s linear infinite;
} */

.rating {
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  cursor: pointer;

  /* font-family: "Days Sans"; */
  background-color: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(4px);
  padding: 0.5rem;
  border-radius: 0.5rem;
  /* font-size: 0.7rem; */
}

.rating:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

.rating img {
  width: 2rem;
}

.currentDraw {
  color: rgba(255, 255, 255, 0.692);
  font-size: 0.8rem;
  position: absolute;
  left: 1rem;
  bottom: 1rem;
}

.currentDraw span {
  color: white;
  font-size: 1.1rem;
}

.superPrize {
  position: absolute;
  top: 0;
  right: 1rem;

  display: flex;
  flex-direction: column;

  font-family: "Days Sans";
  color: var(--gold-2);
  font-size: 0.7rem;
}

.superPrizeEl:nth-child(1) {
  display: flex;
  gap: 0.5rem;
  justify-content: space-between;
}

.superPrizeEl:nth-child(2) {
  text-align: center;
  font-size: 2.3rem;

  text-shadow: 0 0 42px var(--gold-1), 0 0 82px var(--gold-1);
}

.superPrizeEl:nth-child(3) {
  text-align: right;
  font-size: 1.1rem;
}
