.h2 {
  font-size: 1.1rem;
  text-align: center;
}


/* Number of tickets */
.withdrawAddress, .numberUSDZ {
  margin-top: 1rem;
  width: 100%;
  border: none;
  border-radius: 0.5rem;
  font-size: 1rem;
  padding: 0.3rem;
  background: rgba(0, 0, 0, 0.2);
  border: 1px lightgray solid;
  color: white;
}

.withdrawAddress::placeholder, .numberUSDZ::placeholder {
  color: lightgray;
}

.numberUSDZ::-webkit-outer-spin-button,
.numberUSDZ::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.numberUSDZ[type="number"] {
  appearance: textfield;
  -moz-appearance: textfield;
  /* Firefox */
}


.withdrawButton {
  width: 100%;
  padding: 0.5rem;
  border: 2px solid #8706e2;
  position: relative;
  overflow: hidden;
  box-shadow: 1px 1px 25px 10px #9c22f37f;
  margin-top: 2rem;
  border-radius: 0.3rem;
  font-family: 'Days Sans';
  background: var(--violet-2);
  color: white;
  cursor: pointer;
}

.withdrawButton:before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    120deg,
    transparent,
    rgba(146, 148, 248, 0.4),
    transparent
  );
  transition: all 650ms;
}

.withdrawButton:hover:before {
  left: 100%;
}
