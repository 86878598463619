.modal {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.connectZHCashBtn, .connectTONBtn {
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 0.3rem;
    width: 100%;

    font-weight: 600;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;

    cursor: pointer;
}

.connectZHCashBtn {
    background: #ef0000b5;
    color: white;
}

.connectZHCashBtn:hover {
    background: #ef000097;
}

.connectZHCashBtnImg {
    width: 1.5rem;
}



.connectTONBtn {
    background: #0098eb;
    color: white;
}

.connectTONBtn:hover {
    background: #0099ebca;
}

.connectTONBtnImg {
    width: 1rem;
    padding: 0.25rem 0;
}


.connectedContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.addressContainer, .walletActionContainer {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.addressContainer img {
    width: 2rem;
}

.walletActionBtn {
    width: 2rem;
    display: block;
    border: 1px rgba(255, 255, 255, 0.721) solid;
    border-radius: 0.5rem;
    padding: 0.3rem;

    cursor: pointer;
}

.walletActionBtn:hover {
    background: #ffffff4d;
}
