.button {
  border: none;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  font-family: "Days Sans";
  font-size: 1.2rem;
  width: calc(100% - 4rem);
  margin: 1.5rem 2rem;
  cursor: pointer;
  position: relative;
  background-color: #ffc700;
}

.button:before {
  content: "";
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #b775ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -3px;
  left: -3px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 6px);
  height: calc(100% + 6px);
  animation: glowing 20s linear infinite;
  /* opacity: 0; */
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

/* .giftButton:hover:before {
  opacity: 1;
} */

@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.divider {
  border-top: 1px solid gray;
  margin: 0 2rem;
}

.copyReferralLinkButton {
  font-size: 1rem;
  font-family: sans-serif;
  font-weight: 600;
}

/* Referrals tree */
.referrals {
  --spacing: 1.5rem;
  --radius: 10px;
  font-size: 1.1rem;
  margin-bottom: 3rem;
}

.referrals li {
  display: block;
  position: relative;
  padding-left: calc(2 * var(--spacing) - var(--radius) - 2px);
  cursor: pointer;
}

.referrals ul {
  margin-left: calc(var(--radius) - var(--spacing));
  padding-left: 0;
}

.referrals ul li {
  border-left: 2px solid #ddd;
}

.referrals ul li:last-child {
  border-color: transparent;
}

/* Lines */
/* .referrals ul li::before {
  content: "";
  display: block;
  position: absolute;
  top: calc(var(--spacing) / -2);
  left: -2px;
  width: calc(var(--spacing) + 2px);
  height: calc(var(--spacing) + 1px);
  border: solid #ddd;
  border-width: 0 0 2px 2px;
} */

/* Custom markers */
/* .referrals summary {
  display: block;
  cursor: pointer;
}

.referrals summary::marker,
.referrals summary::-webkit-details-marker {
  display: none;
}

.referrals summary:focus {
  outline: none;
}

.referrals summary:focus-visible {
  outline: 1px dotted #000;
}

.referrals li::after,
.referrals summary::before {
  content: "";
  display: block;
  position: absolute;
  top: calc(var(--spacing) / 2 - var(--radius));
  left: calc(var(--spacing) - var(--radius) - 1px);
  width: calc(2 * var(--radius));
  height: calc(2 * var(--radius));
  border-radius: 50%;
  background: #ddd;
}

.referrals summary::before {
  content: "+";
  z-index: 1;
  background: #696;
  color: #fff;
  line-height: calc(2 * var(--radius) - 2px);
  text-align: center;
}

.referrals details[open] > summary::before {
  content: "−";
} */


.noReferrals {
  text-align: center;
  margin: 1rem 0;
}


.winTicketsContainer {
  padding: 0 2rem;
  margin: 1rem 0 1.5rem;
}

.winTicket {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 0.5rem;
}

.payoutBtn {
  border: none;
  width: 6rem;
  border-radius: 2rem;
  font-size: 1rem;
  padding: 0.3rem 0.5rem;
  color: white;
  cursor: pointer;
  font-family: 'Tahoma';

  background: linear-gradient(to bottom, var(--red-1), var(--red-2));
}


.winningTicketsH1 {
  text-align: center;
  margin-top: 1.5rem;
}

.winningTicketsH2 {
  text-align: center;
  font-size: 1rem;
  font-weight: 300;
  margin-top: -1rem;
}


.convertLOTH1 {
  text-align: center;
  margin-top: 1rem;
  font-size: 1rem;
}

.convertLOTBtn {
  border: none;
  width: 100%;
  border-radius: 2rem;
  font-size: 1rem;
  padding: 0.3rem 0.5rem;
  color: white;
  cursor: pointer;
  font-family: 'Tahoma';
  width: calc(100% - 4rem);
  margin: 1rem 2rem 1.5rem;

  background: linear-gradient(to bottom, var(--violet-1), var(--violet-2));
}
