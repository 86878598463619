.buttonPanel {
    width: 100%;
    height: min-content;
    padding: 1.5rem 1rem 1.5rem;
    display: flex;
    gap: 0.7rem;

    border-top: 1px solid var(--bg-3);
    z-index: 3;
}

.button {
    border: none;
    width: 100%;
    border-radius: 2rem;
    font-size: 1rem;
    padding: 0.5rem;
    color: white;
    cursor: pointer;
    font-family: 'Tahoma';
}

.button:nth-child(1) {
    background: linear-gradient(to bottom, var(--green-1), var(--green-2));
}

.button:nth-child(2) {
    background: linear-gradient(to bottom, var(--red-1), var(--red-2));
}

.button:nth-child(3) {
    background: linear-gradient(to bottom, var(--violet-1), var(--violet-2));
}

.button:hover {
    opacity: 0.8;
}