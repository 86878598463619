.header {
  padding: 1rem 1rem 2rem;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header span {
    font-size: 1.2rem;
    border-radius: 0.3rem;
    background-color: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(4px);
    padding: 0.2rem 0.7rem;
    cursor: pointer;
}

.header span:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

.connectWalletBtn {
  border: none;
  width: 7rem;
  border-radius: 2rem;
  /* font-size: 0.8rem; */
  font-size: 1rem;
  /* font-weight: 700; */
  padding: 0.5rem;
  color: white;
  cursor: pointer;

  background: linear-gradient(to bottom, var(--blue-2), #0098eb);

  font-family: 'Tahoma';
}

.connectWalletBtn:hover {
    opacity: 0.8;
}
