.backdrop {
    position: absolute;
    z-index: 999;
    width: 100%;
    height: 100%;
    /* background-color: rgba(255, 255, 255, 0.4); */
    background-color: rgba(0, 0, 0, 0.4);
}

.closeBtn {
    width: 2rem;
    position: absolute;
    top: -2.5rem;
    right: 0;
    background-color: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(5px);
    border-radius: 5rem;
    cursor: pointer;
}

.modal {
    width: 80%;
    border-radius: 1rem;
    border: 1px solid white;
    background-color: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(5px);

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);

    padding: 1rem;
}

.content {
    width: 100%;
    height: 100%;
}