.wheel {
    width: 20rem;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin: 2rem 0 0;
}

.h1 {
    width: 100%;
    font-family: 'Days Sans';
    font-size: 1.7rem;
    text-align: center;

    margin-bottom: 2rem;

    background: linear-gradient(
        to right,
        #c6007b 20%,
        #ff9900 30%,
        #8cc600 70%,
        #00a5c5 80%
    );
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 500% auto;
    animation: textShine 3s ease-in-out infinite alternate;

    margin-top: 3rem;
}

@keyframes textShine {
    0% {
        background-position: 0% 50%;
    }
    100% {
        background-position: 100% 50%;
    }
}

.ticketsContainer {
    width: 100%;
    text-align: center;
    margin-bottom: 2rem;
}


.ticket {
    font-family: 'Days Sans';
    font-size: 1.5rem;
    margin-bottom: 0.4rem;
}

.showFullList {
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    font-size: 1.1rem;
    background: var(--red-1);
    cursor: pointer;
}
