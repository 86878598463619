
.emblemContainer {
    width: 100%;
    height: 9rem;
    /* background-color: var(--bg-1); */
    position: relative;
}

.h1,
.h2 {
  font-family: "Days Sans";
  margin: 0;
  position: absolute;
}

.h2 {
    top: 1rem;
    left: 2rem;
}

.h1 {
    font-size: 4rem;
    top: 3rem;
    left: 50%;
    transform: translateX(-50%);
}

.h1 span:nth-child(1) {
    color: var(--green-1);
}

.h1 span:nth-child(2) {
    color: var(--yellow-1);
}

.h1 span:nth-child(3) {
    color: var(--violet-1);
}

.h1 span:nth-child(4) {
    color: var(--blue-2);
}

.h1 span:nth-child(5) {
    color: var(--red-2);
}

