.h2 {
  font-size: 1.1rem;
  margin-top: 2rem;
}

.h2First {
  margin-top: 0;
}

.choosePaymentMethodContainer {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.paymentMethodZHCash,
.paymentMethodTON {
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 0.3rem;
  width: 100%;

  font-weight: 600;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  cursor: pointer;
}

.paymentMethodZHCash {
  background: #ef0000b5;
  color: white;
  border: 2px solid #ef0000b5;
}

.paymentMethodZHCash:hover {
  background: #ef000097;
  border: 2px solid #ef000097;
}

.paymentMethodZHCash img {
  width: 1.5rem;
}

.paymentMethodTON {
  background: #0098eb;
  color: white;
  border: 2px solid #0098eb;
}

.paymentMethodTON:hover {
  background: #0099ebca;
  border: 2px solid #0099ebca;
}

.paymentMethodTON img {
  width: 1rem;
  padding: 0.25rem 0;
}

.paymentMethodZHCash:disabled,
.paymentMethodTON:disabled {
  cursor: not-allowed;
  background-color: gray;
  border: 2px solid gray;

}

.active {
  border: 2px solid var(--yellow-1);
}

.active:hover {
  border: 2px solid var(--yellow-1);
}

/* Number of tickets */
.numberTickets {
  margin-top: 1rem;
  width: 100%;
  border: none;
  border-radius: 0.5rem;
  font-size: 1rem;
  padding: 0.3rem;
  background: rgba(0, 0, 0, 0.2);
  border: 1px lightgray solid;
  color: white;
}

.numberTickets::-webkit-outer-spin-button,
.numberTickets::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.numberTickets[type="number"] {
  appearance: textfield;
  -moz-appearance: textfield;
  /* Firefox */
}



.buyButton {
  width: 100%;
  padding: 0.5rem;
  border: 2px solid #8706e2;
  position: relative;
  overflow: hidden;
  box-shadow: 1px 1px 25px 10px #9c22f37f;
  margin-top: 2rem;
  border-radius: 0.3rem;
  font-family: 'Days Sans';
  background: var(--violet-2);
  color: white;
  cursor: pointer;
}

.buyButton:before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    120deg,
    transparent,
    rgba(146, 148, 248, 0.4),
    transparent
  );
  transition: all 650ms;
}

.buyButton:hover:before {
  left: 100%;
}

.additInfo {
  font-size: 0.8rem;
}
